import { ThemeOptions } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { common } from "./common";

export const light: ThemeOptions = deepmerge(
    {
        palette: {
            primary: {
                light: "#009eff",
                main: "#405fd2",
                dark: "#000080",
                contrastText: "#fff",
            },
            secondary: {
                main: "#e10050",
                contrastText: "#fff",
            },
            background: {
                default: "#f3f3f4",
                paper: "#fff",
            },
            action: {
                disabled: "rgba(0,0,0,0.38)", // text
                disabledBackground: "rgba(0,0,0,0.26)", // border
            },
            success: {
                main: "#1d8f42",
                light: "#dafada",
                contrastText: "#1d8f42",
            },
            error: {
                main: "#e9192d",
                light: "#fde1d6",
                contrastText: "#e9192d",
            },
            warning: {
                main: "#ea8d03",
            },
            common: {
                black: "#000",
                white: "#fff",
            },
            grey: {
                "200": "#e8e8e8",
                "300": "#e0e0e0",
                "400": "#bcbcbc",
                "500": "#8e97a5",
            },
        },
        components: {
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#e0e0e0",
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    colorPrimary: { color: "#009eff" },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: "#fafafa",
                    },
                },
            },
        },
    },
    common,
);
