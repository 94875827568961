import { ThemeOptions } from "@mui/material";

function container(): Element {
    // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
    // https://github.com/mui/material-ui/issues/15618
    return document.fullscreenElement ?? document.body;
}

export const common: ThemeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    margin: 0,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: { fontSize: "16px" },
                fontSizeLarge: { fontSize: "32px" },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: { textTransform: "none", borderRadius: "0px" },
                sizeLarge: {
                    fontSize: "16px",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: { textTransform: "none" },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: { whiteSpace: "nowrap" },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    boxSizing: "border-box",
                    height: "40px",
                    padding: "0 1em",
                },
                body: {
                    borderStyle: "none",
                    boxSizing: "border-box",
                    height: "40px",
                    padding: "0 1em",
                    backgroundColor: "transparent",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: { height: "20px", marginTop: "4px" },
            },
        },
        MuiPopper: {
            defaultProps: {
                container,
            },
        },
        MuiModal: {
            defaultProps: {
                container,
            },
        },
    },
    shape: {
        borderRadius: 4, // コンポーネント全体にに丸みをもたせる
    },
    typography: {
        fontFamily: "Arial, メイリオ",
        htmlFontSize: 16,
        fontWeightLight: 400,
        fontWeightRegular: 400,
        fontWeightMedium: 400,
        fontWeightBold: 700,
        h1: {
            fontSize: "6rem", // 96px
            lineHeight: 1.167, // 112.032px
            letterSpacing: "-0.01562em", // -1.49952px
        },
        h2: {
            fontSize: "3.75rem", // 60px
            lineHeight: 1.2, // 72px
            letterSpacing: "-0.00833em", // -0.4998px
        },
        h3: {
            fontSize: "3rem", // 48px
            lineHeight: 1.167, // 56.016
            letterSpacing: "0em", // 0px
        },
        h4: {
            fontSize: "2.125rem", // 34px
            lineHeight: 1.235, // 41.99px
            letterSpacing: "0.00735em", // 0.2499px
        },
        h5: {
            fontSize: "1.5rem", // 24px
            lineHeight: 1.334, // 32.016px
            letterSpacing: "0em", // 0px
        },
        h6: {
            fontSize: "1.125rem", // 18px
            lineHeight: 1.6, // 32px
            letterSpacing: "0.0075em", // 0.15px
        },
        subtitle1: {
            fontSize: "1rem", // 16px
            lineHeight: 1.75, // 28px
            letterSpacing: "0.00938em", // 0.15008px
        },
        subtitle2: {
            fontSize: "0.875rem", // 14px
            lineHeight: 1.57, // 21.98px
            letterSpacing: "0.00714em", // 0.09996px
        },
        body1: {
            fontSize: "1rem", // 16px
            lineHeight: 1.5, // 24px
            letterSpacing: "0.00938em", // 0.15008px
        },
        body2: {
            fontSize: "0.875rem", // 14px
            lineHeight: 1.43, // 20.02px
            letterSpacing: "0.01071em", // 0.14994px
        },
        button: {
            fontSize: "0.875rem", // 14px
            lineHeight: 1.75, // 24.5px
            letterSpacing: "0.02857em", // 0.39998px
            textTransform: "uppercase", // すべて大文字にする
        },
        caption: {
            fontSize: "0.75rem", // 12px
            lineHeight: 1.66, // 19.92px
            letterSpacing: "0.03333em", // 0.39996px
        },
        overline: {
            fontSize: "0.75rem", // 12px
            lineHeight: 2.66, // 31.92px
            letterSpacing: "0.08333em", // 0.99996px
            textTransform: "uppercase", // すべて大文字にする
        },
    },
};
