export type ThemeParameter = {
    followOS?: boolean;
    darkEnable?: boolean;
};

export class ThemeController {
    static readonly THEME_KEY: string = "sereca-theme";

    /**
     * ダークモード有効か無効か計算する処理
     * @returns
     */
    static isDarkMode(parameter: ThemeParameter | undefined): boolean {
        // 優先順位は以下の通り
        // 1. OSの設定に従う
        // 2. ローカルストレージのDark/Light設定に従う
        // 上記以外はLight設定
        if (parameter) {
            const { followOS, darkEnable } = parameter;
            if (followOS) {
                return window.matchMedia("(prefers-color-scheme: dark)")
                    .matches;
            }
            if (darkEnable) {
                return true;
            }
        }
        return false;
    }

    /**
     * ローカルストレージからテーマ設定を取得する処理
     * 取得できない場合は OS の設定に従う
     */
    static loadThemeParameter(): ThemeParameter {
        const followOS = { followOS: true };
        try {
            const theme = localStorage.getItem(ThemeController.THEME_KEY);
            if (theme) {
                return JSON.parse(theme);
            }
            return followOS;
        } catch (error) {
            console.error(error);
            return followOS;
        }
    }

    static saveThemeParameter(parameter: ThemeParameter): void {
        localStorage.setItem(
            ThemeController.THEME_KEY,
            JSON.stringify(parameter),
        );
    }
}
