import { ThemeOptions } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { common } from "./common";

export const dark: ThemeOptions = deepmerge(
    {
        palette: {
            mode: "dark",
            primary: {
                main: "#42549A",
                light: "#90CAF9",
                dark: "#00639F",
            },
            background: {
                default: "#101011",
                paper: "#1B1D1E",
            },
            success: {
                main: "#009B76",
                dark: "#009B76",
                contrastText: "#FFFFFF",
            },
            error: {
                main: "#FD637D",
                dark: "#FD637D",
                contrastText: "#FFFFFF",
            },
            warning: {
                main: "#EA8D03",
            },
            divider: "#4E4E4E",
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#454F6E",
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#303338",
                    },
                    indicator: {
                        backgroundColor: "#009EFF",
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            color: "#FFFFFF",
                        },
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            color: "#90CAF9",
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: "#1B1D1E",
                        borderBottomColor: "#4E4E4E",
                    },
                },
            },
        },
    },
    common,
);
