import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    ThemeController,
    ThemeParameter,
} from "../../controller/theme-controller";
import { dark } from "../themes/dark";
import { light } from "../themes/light";

export type ThemeContextData = {
    themeParameter: ThemeParameter | undefined;
    changeThemeParameter: (parameter: ThemeParameter) => void;
};

export function useThemeParameter(): ThemeContextData | undefined {
    return useContext(ThemeContext);
}

export namespace ThemeProvider {
    export interface Props {
        readonly children?: ReactNode | undefined;
    }
}

export function ThemeProvider({ children }: ThemeProvider.Props): JSX.Element {
    const [themeParameter, setThemeParameter] = useState<ThemeParameter>();
    const theme = useMemo(
        () =>
            createTheme(
                ThemeController.isDarkMode(themeParameter) ? dark : light,
            ),
        [themeParameter],
    );

    const changeThemeParameter = useCallback(
        (parameter: ThemeParameter | undefined) => {
            if (parameter) {
                ThemeController.saveThemeParameter(parameter);
                setThemeParameter(parameter);
            }
        },
        [],
    );

    const value = useMemo<ThemeContextData>(
        () => ({ changeThemeParameter, themeParameter }),
        [changeThemeParameter, themeParameter],
    );

    useEffect(() => {
        setThemeParameter(ThemeController.loadThemeParameter());
    }, []);

    return React.createElement(
        MuiThemeProvider,
        { theme },
        React.createElement(ThemeContext.Provider, { value }, children),
    );
}

const ThemeContext = createContext<ThemeContextData | undefined>(undefined);
